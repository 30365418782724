:root {
scroll-behavior:smooth;}
body {
font-family:"Open Sans",sans-serif;
    color:#d7d7d7;
    background:#000;}
a {
color:#d7d7d7;
    text-decoration:none;}
a:hover {
color:#b2aeae;
    text-decoration:none;}
h1,h2,h3,h4,h5,h6 {
font-family:"Nunito",sans-serif;}
section {
padding:60px 0;
    overflow:hidden;}
.section-header {
text-align:center;
    padding-bottom:40px;}
.section-header h2 {
font-size:13px;
    letter-spacing:1px;
    font-weight:700;
    margin:0;
    color:#f03f3e;
    text-transform:uppercase;}
.section-header p {
margin:10px 0 0 0;
    padding:0;
    font-size:38px;
    line-height:42px;
    font-weight:700;
    color:#fff;
    text-align:center;}
.features .section-header p {
margin:10px 0 0 0;
    padding:0;
    font-size:38px;
    line-height:42px;
    font-weight:700;
    color:#fff;
    text-align:center;}
@media (max-width:768px) {
.section-header p {
font-size:28px;
    line-height:32px;}}
.breadcrumbs {
padding:15px 0;
    background:#012970;
    min-height:40px;
    margin-top:82px;
    color:#fff;}
@media (max-width:992px) {
.breadcrumbs {
margin-top:57px;}}
.breadcrumbs h2 {
font-size:28px;
    font-weight:500;}
.breadcrumbs ol {
display:flex;
    flex-wrap:wrap;
    list-style:none;
    padding:0 0 10px 0;
    margin:0;
    font-size:14px;}
.breadcrumbs ol a {
color:#fff;
    transition:0.3s;}
.breadcrumbs ol a:hover {
text-decoration:underline;}
.breadcrumbs ol li+li {
padding-left:10px;}
.breadcrumbs ol li+li::before {
display:inline-block;
    padding-right:10px;
    color:#8894f6;
    content:"/";}
.back-to-top {
position:fixed;
    visibility:hidden;
    opacity:0;
    right:15px;
    bottom:15px;
    z-index:99999;
    background:#f4403f;
    width:40px;
    height:40px;
    border-radius:4px;
    transition:all 0.4s;}
.back-to-top i {
font-size:24px;
    color:#fff;
    line-height:0;}
.back-to-top:hover {
background:#6776f4;
    color:#fff;}
.back-to-top.active {
visibility:visible;
    opacity:1;}
@media screen and (max-width:768px) {
[data-aos-delay] {
transition-delay:0 !important;}}
.header {
transition:all 0.5s;
    z-index:997;
    padding:20px 0;
    background:linear-gradient(to bottom,rgb(0 0 0) 0%,rgb(0 0 0 / 81%) 50%,rgb(0 0 0 / 0%) 100%)}
.header.header-scrolled {
padding:15px 0;}
.header .logo {
line-height:0;}
.header .logo img {
max-height:40px;
    margin-right:6px;}
.header .logo span {
font-size:30px;
    font-weight:700;
    letter-spacing:1px;
    color:#d7d7d7;
    font-family:"Nunito",sans-serif;
    margin-top:3px;}
.navbar {
padding:0;}
.navbar ul {
margin:0;
    padding:0;
    display:flex;
    list-style:none;
    align-items:center;}
.navbar li {
position:relative;}
.navbar a,.navbar a:focus {
display:flex;
    align-items:center;
    justify-content:space-between;
    padding:10px 0 10px 30px;
    font-family:"Nunito",sans-serif;
    font-size:16px;
    font-weight:700;
    color:#fff;
    white-space:nowrap;
    transition:0.3s;}
.navbar a i,.navbar a:focus i {
font-size:12px;
    line-height:0;
    margin-left:5px;}
.navbar a:hover,.navbar .active,.navbar .active:focus,.navbar li:hover>a {
color:#fff;}
.navbar .getstarted,.navbar .getstarted:focus {
background:#f4403f;
    padding:8px 20px;
    margin-left:30px;
    border-radius:4px;
    color:#fff;}
.navbar .getstarted:hover,.navbar .getstarted:focus:hover {
color:#fff;
    background:#be3838;}
.navbar .dropdown ul {
display:block;
    position:absolute;
    left:14px;
    top:calc(100% + 30px);
    margin:0;
    padding:10px 0;
    z-index:99;
    opacity:0;
    visibility:hidden;
    background:#fff;
    box-shadow:0px 0px 30px rgba(127,137,161,0.25);
    transition:0.3s;
    border-radius:4px;}
.navbar .dropdown ul li {
min-width:200px;}
.navbar .dropdown ul a {
padding:10px 20px;
    font-size:15px;
    text-transform:none;
    font-weight:600;}
.navbar .dropdown ul a i {
font-size:12px;}
.navbar .dropdown ul a:hover,.navbar .dropdown ul .active:hover,.navbar .dropdown ul li:hover>a {
color:#f4403f;}
.navbar .dropdown:hover>ul {
opacity:1;
    top:100%;
    visibility:visible;}
.navbar .dropdown .dropdown ul {
top:0;
    left:calc(100% - 30px);
    visibility:hidden;}
.navbar .dropdown .dropdown:hover>ul {
opacity:1;
    top:0;
    left:100%;
    visibility:visible;}
@media (max-width:1366px) {
.navbar .dropdown .dropdown ul {
left:-90%;}
.navbar .dropdown .dropdown:hover>ul {
left:-100%;}}
.mobile-nav-toggle {
color:#d7d7d7;
    font-size:28px;
    cursor:pointer;
    display:none;
    line-height:0;
    transition:0.5s;}
.mobile-nav-toggle.bi-x {
color:#fff;}
@media (max-width:991px) {
.mobile-nav-toggle {
display:block;}
.navbar ul {
display:none;}}
.navbar-mobile {
position:fixed;
    overflow:hidden;
    top:0;
    right:0;
    left:0;
    bottom:0;
    background:rgba(1,22,61,0.9);
    transition:0.3s;}
.navbar-mobile .mobile-nav-toggle {
position:absolute;
    top:15px;
    right:15px;}
.navbar-mobile ul {
display:block;
    position:absolute;
    top:55px;
    right:15px;
    bottom:15px;
    left:15px;
    padding:10px 0;
    border-radius:10px;
    background-color:#fff;
    overflow-y:auto;
    transition:0.3s;}
.navbar-mobile a,.navbar-mobile a:focus {
padding:10px 20px;
    font-size:15px;
    color:#d7d7d7;}
.navbar-mobile a:hover,.navbar-mobile .active,.navbar-mobile li:hover>a {
color:#f4403f;}
.navbar-mobile .getstarted,.navbar-mobile .getstarted:focus {
margin:15px;}
.navbar-mobile .dropdown ul {
position:static;
    display:none;
    margin:10px 20px;
    padding:10px 0;
    z-index:99;
    opacity:1;
    visibility:visible;
    background:#fff;
    box-shadow:0px 0px 30px rgba(127,137,161,0.25);}
.navbar-mobile .dropdown ul li {
min-width:200px;}
.navbar-mobile .dropdown ul a {
padding:10px 20px;}
.navbar-mobile .dropdown ul a i {
font-size:12px;}
.navbar-mobile .dropdown ul a:hover,.navbar-mobile .dropdown ul .active:hover,.navbar-mobile .dropdown ul li:hover>a {
color:#f4403f;}
.navbar-mobile .dropdown>.dropdown-active {
display:block;}
.hero {
width:100%;
    height:100vh;
    background-size:cover;}
.bgimg {
width:100%;
    height:100vh;
    background:url(../img/pks2.webp) top center no-repeat;
    background-size:cover;
    position:relative;
    z-index:999999;
    transition:1s ease-in-out;}
.bgimg:hover {
height:100vh;
    background:url(../img/pks3.webp) top center no-repeat;
    background-size:cover;}
.hero h1 {
margin:0;
    font-size:48px;
    font-weight:700;
    color:#d7d7d7;}
.hero h2 {
color:#444444;
    margin:15px 0 0 0;
    font-size:26px;}
.hero .btn-get-started {
margin-top:30px;
    line-height:0;
    padding:15px 40px;
    border-radius:4px;
    transition:0.5s;
    color:#ebebeb;
    box-shadow:0px 5px 30px rgba(65,84,241,0.4);
    border:1px solid #fff;}
.hero .btn-get-started span {
font-family:"Nunito",sans-serif;
    font-weight:600;
    font-size:16px;
    letter-spacing:1px;}
.hero .btn-get-started i {
margin-left:5px;
    font-size:18px;
    transition:0.3s;}
.hero .btn-get-started:hover i {
transform:translateX(5px);}
.hero .hero-img {
text-align:right;}
@media (min-width:1024px) {
.hero {
background-attachment:fixed;}}
@media (max-width:991px) {
.hero {
height:auto;
    padding:120px 0 60px 0;}
.hero .hero-img {
text-align:center;
    margin-top:20px;}
.hero .hero-img img {
width:80%;}}
@media (max-width:768px) {
.hero {
text-align:center;}
.hero h1 {
font-size:32px;}
.hero h2 {
font-size:24px;}
.hero .hero-img img {
width:100%;}}
.about .content {
padding:40px;}
.about h3 {
font-size:15px;
    font-weight:700;
    color:#fff;
    text-transform:uppercase;}
.about h2 {
font-size:24px;
    font-weight:700;
    color:#f4403f;}
.about p {
font-size:17px;
    color:#fff;
    text-align:justify;}
.about .btn-read-more {
line-height:0;
    padding:15px 40px;
    border-radius:4px;
    transition:0.5s;
    color:#fff;
    background:#f4403f;
    box-shadow:0px 5px 25px rgba(65,84,241,0.3);}
.about .btn-read-more span {
font-family:"Nunito",sans-serif;
    font-weight:600;
    font-size:16px;
    letter-spacing:1px;}
.about .btn-read-more i {
margin-left:5px;
    font-size:18px;
    transition:0.3s;}
.about .btn-read-more:hover i {
transform:translateX(5px);}
.values .box {
padding:30px;
    box-shadow:1px 0 2px rgb(94 96 100 / 56%);
    text-align:center;
    transition:0.3s;
    height:100%;
    color:#b6b6b6;
    background:#000000c2;
    border:1px solid #333131;}
.values .box img {
padding:30px 50px;
    transition:0.5s;
    transform:scale(1.1);}
.values .box h3 {
font-size:24px;
    color:#fff;
    font-weight:700;
    margin-bottom:18px;}
.values .box p {
text-align:justify;}
.values .box:hover {
box-shadow:0px 0 30px rgba(1,41,112,0.08);}
.values .box:hover img {
transform:scale(1);}
.counts {
padding:70px 0 60px;
    background:#000;}
.counts .count-box {
display:block;
    align-items:center;
    padding:30px;
    width:100%;
    background:#fff;
    box-shadow:0px 0 30px rgba(1,41,112,0.08);
    text-align:center;}
.counts .count-box i {
font-size:90px;
    line-height:0;
    margin-right:20px;
    color:#f4403f;
    display:block;
    margin-bottom:20px;}
.counts .count-box span {
font-size:36px;
    display:block;
    font-weight:600;
    color:#0b198f;}
.counts .count-box p {
padding:0;
    margin:0;
    font-family:"Nunito",sans-serif;
    font-size:18px;
    color:black;
    font-weight:700;}
.features .feature-box {
padding:25px 20px;
    box-shadow:0px 0 30px rgba(1,41,112,0.08);
    transition:0.3s;
    height:100%;
    position:relative;
    background:#ffffff0f;}
.features .img-fluid {
animation:movings 5s infinite;
    -webkit-animation:movings 5s infinite}
@keyframes movings {
0% {
-webkit-transform:rotate(-1deg) translate(-2px,-2px);
    transform:rotate(-1deg) translate(-2px,-2px)}
50% {
-webkit-transform:rotate(1deg) translate(2px,2px);
    transform:rotate(1deg) translate(2px,2px)}
to {
-webkit-transform:rotate(-1deg) translate(-2px,-2px);
    transform:rotate(-1deg) translate(-2px,-2px)}}
.features p {
text-align:justify;}
.features .feature-box h3 {
font-size:18px;
    color:#d7d7d7;
    font-weight:700;
    margin:0;}
.features .feature-box i {
line-height:0;
    background:#f4403f;
    padding:10px;
    margin-right:10px;
    font-size:3em;
    border-radius:3px;
    transition:0.3s;}
.features .feature-box:hover i {
background:#fff;
    color:#f4403f;}
.features .feture-tabs {
margin-top:40px;}
.features .feture-tabs h3 {
color:#d7d7d7;
    font-weight:700;
    font-size:32px;
    margin-bottom:10px;}
@media (max-width:768px) {
.features .feture-tabs h3 {
font-size:28px;}}
.features .feture-tabs .nav-pills {}
.features .feture-tabs .nav-link {
background:none;
    text-transform:uppercase;
    font-size:15px;
    font-weight:600;
    color:#d7d7d7;
    padding:12px 0;
    margin-right:25px;
    margin-bottom:-2px;
    border-radius:0;}
.features .feture-tabs .nav-link.active {
color:#be3838;
    border-bottom:3px solid #fff;}
.features .feture-tabs .tab-content h4 {
font-size:18px;
    margin:0;
    font-weight:700;
    color:#be3838 !important;}
.features .feture-tabs .tab-content i {
font-size:24px;
    line-height:0;
    margin-right:8px;
    color:#be3838;}
.features .feature-icons {
margin-top:40px;}
.features .feature-icons h3 {
color:#d7d7d7;
    font-weight:700;
    font-size:32px;
    margin-bottom:20px;
    text-align:center;}
@media (max-width:768px) {
.features .feature-icons h3 {
font-size:28px;}}
.features .feature-icons .content .icon-box {
display:flex;}
.features .feature-icons .content .icon-box h4 {
font-size:20px;
    font-weight:700;
    margin:0 0 10px 0;
    color:#d7d7d7;}
.features .feature-icons .content .icon-box i {
font-size:45px;
    line-height:44px;
    color:#ee3e3e;
    margin-right:15px;}
.features .feature-icons .content .icon-box p {
font-size:15px;
    color:#a7a5a5;
    text-align:justify;}
.services .service-box {
box-shadow:0px 0 30px rgba(1,41,112,0.08);
    height:100%;
    padding:60px 30px;
    text-align:center;
    transition:0.3s;
    border-radius:5px;
    background:#000;}
.services .service-box .icon {
font-size:60px;
    padding:40px 20px;
    border-radius:4px;
    position:relative;
    margin-bottom:25px;
    display:inline-block;
    line-height:0;
    transition:0.3s;}
.services .service-box h3 {
color:#fffde7;
    font-weight:700;}
.services .service-box .read-more {
display:inline-flex;
    align-items:center;
    justify-content:center;
    font-weight:600;
    font-size:16px;
    padding:8px 20px;}
.services .service-box .read-more i {
line-height:0;
    margin-left:5px;
    font-size:18px;}
.services .service-box.blue {
border-bottom:3px solid #2db6fa;}
.services .service-box.blue .icon {
color:#2db6fa;}
.services .service-box.blue .read-more {
color:#2db6fa;}
.services .service-box.blue:hover {
background:#2db6fa;}
.services .service-box.orange {
border-bottom:3px solid #f68c09;}
.services .service-box.orange .icon {
color:#f68c09;}
.services .service-box.orange .read-more {
color:#f68c09;}
.services .service-box.orange:hover {
background:#f68c09;}
.services .service-box.green {
border-bottom:3px solid #08da4e;}
.services .service-box.green .icon {
color:#08da4e;}
.services .service-box.green .read-more {
color:#08da4e;}
.services .service-box.green:hover {
background:#08da4e;}
.services .service-box.red {
border-bottom:3px solid #e9222c;}
.services .service-box.red .icon {
color:#e9222c;}
.services .service-box.red .read-more {
color:#e9222c;}
.services .service-box.red:hover {
background:#e9222c;}
.services .service-box.purple {
border-bottom:3px solid #b50edf;}
.services .service-box.purple .icon {
color:#b50edf;}
.services .service-box.purple .read-more {
color:#b50edf;}
.services .service-box.purple:hover {
background:#b50edf;}
.services .service-box.pink {
border-bottom:3px solid #f51f9c;}
.services .service-box.pink .icon {
color:#f51f9c;}
.services .service-box.pink .read-more {
color:#f51f9c;}
.services .service-box.pink:hover {
background:#f51f9c;}
.services .service-box:hover h3,.services .service-box:hover p,.services .service-box:hover .read-more {
color:#fff;}
.services .service-box:hover .icon {
color:#fff;}
.pricing .box {
padding:40px 20px;
    background:#fff;
    text-align:center;
    box-shadow:0px 0 30px rgba(1,41,112,0.08);
    border-radius:4px;
    position:relative;
    overflow:hidden;
    transition:0.3s;}
.pricing .box:hover {
transform:scale(1.1);
    box-shadow:0px 0 30px rgba(1,41,112,0.1);}
.pricing h3 {
font-weight:700;
    font-size:18px;
    margin-bottom:15px;}
.pricing .price {
font-size:36px;
    color:#444444;
    font-weight:600;
    font-family:"Poppins",sans-serif;}
.pricing .price sup {
font-size:20px;
    top:-15px;
    left:-3px;}
.pricing .price span {
color:#bababa;
    font-size:16px;
    font-weight:300;}
.pricing img {
padding:30px 40px;}
.pricing ul {
padding:0;
    list-style:none;
    color:#444444;
    text-align:center;
    line-height:26px;
    font-size:16px;
    margin-bottom:25px;}
.pricing ul li {
padding-bottom:10px;}
.pricing ul .na {
color:#ccc;
    text-decoration:line-through;}
.pricing .btn-buy {
display:inline-block;
    padding:8px 40px 10px 40px;
    border-radius:50px;
    color:#f4403f;
    transition:none;
    font-size:16px;
    font-weight:400;
    font-family:"Nunito",sans-serif;
    font-weight:600;
    transition:0.3s;
    border:1px solid #f4403f;}
.pricing .btn-buy:hover {
background:#f4403f;
    color:#fff;}
.pricing .featured {
width:200px;
    position:absolute;
    top:18px;
    right:-68px;
    transform:rotate(45deg);
    z-index:1;
    font-size:14px;
    padding:1px 0 3px 0;
    background:#f4403f;
    color:#fff;}
.faq .accordion-collapse {
border:0;}
.faq .accordion-button {
padding:15px 15px 20px 0;
    font-weight:600;
    border:0;
    font-size:18px;
    color:#444444;
    text-align:left;}
.faq .accordion-button:focus {
box-shadow:none;}
.faq .accordion-button:not(.collapsed) {
background:none;
    color:#f4403f;
    border-bottom:0;}
.faq .accordion-body {
padding:0 0 25px 0;
    border:0;}
.portfolio #portfolio-flters {
padding:0;
    margin:0 auto 25px auto;
    list-style:none;
    text-align:center;
    border-radius:50px;
    padding:2px 15px;}
.portfolio #portfolio-flters li {
cursor:pointer;
    display:inline-block;
    padding:8px 20px 10px 20px;
    font-size:15px;
    font-weight:600;
    background:#fff;
    line-height:1;
    color:#444444;
    margin:0 4px 8px 4px;
    transition:0.3s;
    border-radius:50px;
    border:1px solid #fff;}
.portfolio #portfolio-flters li:hover,.portfolio #portfolio-flters li.filter-active {
color:#f4403f;
    border-color:#f4403f;}
.portfolio #portfolio-flters li:last-child {
margin-right:0;}
.portfolio .portfolio-wrap {
transition:0.3s;
    position:relative;
    overflow:hidden;
    z-index:1;
    background:rgba(255,255,255,0.75);}
.portfolio .portfolio-wrap::before {
content:"";
    background:rgba(255,255,255,0.75);
    position:absolute;
    left:30px;
    right:30px;
    top:30px;
    bottom:30px;
    transition:all ease-in-out 0.3s;
    z-index:2;
    opacity:0;}
.portfolio .portfolio-wrap img {
transition:1s;
    width:100%;
    height:auto;}
.portfolio .portfolio-wrap .portfolio-info {
opacity:0;
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    text-align:center;
    z-index:3;
    transition:all ease-in-out 0.3s;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;}
.portfolio .portfolio-wrap .portfolio-info::before {
display:block;
    content:"";
    width:48px;
    height:48px;
    position:absolute;
    top:35px;
    left:35px;
    border-top:3px solid rgba(1,41,112,0.2);
    border-left:3px solid rgba(1,41,112,0.2);
    transition:all 0.5s ease 0s;
    z-index:9994;}
.portfolio .portfolio-wrap .portfolio-info::after {
display:block;
    content:"";
    width:48px;
    height:48px;
    position:absolute;
    bottom:35px;
    right:35px;
    border-bottom:3px solid rgba(1,41,112,0.2);
    border-right:3px solid rgba(1,41,112,0.2);
    transition:all 0.5s ease 0s;
    z-index:9994;}
.portfolio .portfolio-wrap .portfolio-info h4 {
font-size:20px;
    color:#d7d7d7;
    font-weight:700;}
.portfolio .portfolio-wrap .portfolio-info p {
color:#d7d7d7;
    font-weight:600;
    font-size:14px;
    text-transform:uppercase;
    padding:0;
    margin:0;}
.portfolio .portfolio-wrap .portfolio-links {
text-align:center;
    z-index:4;}
.portfolio .portfolio-wrap .portfolio-links a {
color:#fff;
    background:#f4403f;
    margin:10px 2px;
    width:36px;
    height:36px;
    display:inline-flex;
    align-items:center;
    justify-content:center;
    border-radius:50%;
    transition:0.3s;}
.portfolio .portfolio-wrap .portfolio-links a i {
font-size:24px;
    line-height:0;}
.portfolio .portfolio-wrap .portfolio-links a:hover {
background:#5969f3;}
.portfolio .portfolio-wrap:hover img {
transform:scale(1.1);}
.portfolio .portfolio-wrap:hover::before {
top:0;
    left:0;
    right:0;
    bottom:0;
    opacity:1;}
.portfolio .portfolio-wrap:hover .portfolio-info {
opacity:1;}
.portfolio .portfolio-wrap:hover .portfolio-info::before {
top:15px;
    left:15px;}
.portfolio .portfolio-wrap:hover .portfolio-info::after {
bottom:15px;
    right:15px;}
.portfolio-details {
padding-top:40px;}
.portfolio-details .portfolio-details-slider img {
width:100%;}
.portfolio-details .portfolio-details-slider .swiper-pagination {
margin-top:20px;
    position:relative;}
.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
width:12px;
    height:12px;
    background-color:#fff;
    opacity:1;
    border:1px solid #f4403f;}
.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
background-color:#f4403f;}
.portfolio-details .portfolio-info {
padding:30px;
    box-shadow:0px 0 30px rgba(1,41,112,0.08);}
.portfolio-details .portfolio-info h3 {
font-size:22px;
    font-weight:700;
    margin-bottom:20px;
    padding-bottom:20px;
    border-bottom:1px solid #eee;}
.portfolio-details .portfolio-info ul {
list-style:none;
    padding:0;
    font-size:15px;}
.portfolio-details .portfolio-info ul li+li {
margin-top:10px;}
.portfolio-details .portfolio-description {
padding-top:30px;}
.portfolio-details .portfolio-description h2 {
font-size:26px;
    font-weight:700;
    margin-bottom:20px;}
.portfolio-details .portfolio-description p {
padding:0;}
.testimonials .testimonial-item {
box-sizing:content-box;
    padding:30px;
    margin:40px 30px;
    box-shadow:0px 0 20px rgba(1,41,112,0.1);
    border:1px solid #fff;
    min-height:200px;
    display:flex;
    flex-direction:column;
    text-align:center;
    transition:0.3s;
    min-height:240px;}
.testimonials .testimonial-item .stars {
margin-bottom:15px;}
.testimonials .testimonial-item .stars i {
color:#ffc107;
    margin:0 1px;}
.testimonials .testimonial-item .testimonial-img {
width:90px;
    border-radius:50%;
    border:4px solid #fff;
    margin:0 auto;}
.testimonials .testimonial-item h3 {
font-size:18px;
    font-weight:bold;
    margin:10px 0 5px 0;
    color:#f4403f;}
.testimonials .testimonial-item h4 {
font-size:14px;
    color:#999;
    margin:0;}
.testimonials .testimonial-item p {
font-style:italic;
    margin:0 auto 15px auto;}
.testimonials .swiper-pagination {
margin-top:20px;
    position:relative;}
.testimonials .swiper-pagination .swiper-pagination-bullet {
width:12px;
    height:12px;
    background-color:#fff;
    opacity:1;
    border:1px solid #f4403f;}
.testimonials .swiper-pagination .swiper-pagination-bullet-active {
background-color:#f4403f;}
.testimonials .swiper-slide {
opacity:0.3;}
@media (max-width:1199px) {
.testimonials .swiper-slide-active {
opacity:1;}
.testimonials .swiper-pagination {
margin-top:0;}}
@media (min-width:1200px) {
.testimonials .swiper-slide-next {
opacity:1;
    transform:scale(1.12);}}
.team {
background:#fff;
    padding:60px 0;}
.team .member {
overflow:hidden;
    text-align:center;
    border-radius:5px;
    background:#fff;
    box-shadow:0px 0 30px rgba(1,41,112,0.08);
    transition:0.3s;}
.team .member .member-img {
position:relative;
    overflow:hidden;}
.team .member .member-img:after {
position:absolute;
    content:"";
    left:0;
    bottom:0;
    height:100%;
    width:100%;
    background:url(../img/team-shape.svg) no-repeat center bottom;
    background-size:contain;
    z-index:1;}
.team .member .social {
position:absolute;
    right:-100%;
    top:30px;
    opacity:0;
    border-radius:4px;
    transition:0.5s;
    background:rgba(255,255,255,0.3);
    z-index:2;}
.team .member .social a {
transition:color 0.3s;
    color:rgba(1,41,112,0.5);
    margin:15px 12px;
    display:block;
    line-height:0;
    text-align:center;}
.team .member .social a:hover {
color:rgba(1,41,112,0.8);}
.team .member .social i {
font-size:18px;}
.team .member .member-info {
padding:10px 15px 20px 15px;}
.team .member .member-info h4 {
font-weight:700;
    margin-bottom:5px;
    font-size:20px;
    color:#d7d7d7;}
.team .member .member-info span {
display:block;
    font-size:14px;
    font-weight:400;
    color:#aaaaaa;}
.team .member .member-info p {
font-style:italic;
    font-size:14px;
    padding-top:15px;
    line-height:26px;
    color:#5e5e5e;}
.team .member:hover {
transform:scale(1.08);
    box-shadow:0px 0 30px rgba(1,41,112,0.1);}
.team .member:hover .social {
right:8px;
    opacity:1;}
.clients .clients-slider .swiper-slide img {
opacity:0.5;
    transition:0.3s;}
.clients .clients-slider .swiper-slide img:hover {
opacity:1;}
.clients .clients-slider .swiper-pagination {
margin-top:20px;
    position:relative;}
.clients .clients-slider .swiper-pagination .swiper-pagination-bullet {
width:12px;
    height:12px;
    background-color:#fff;
    opacity:1;
    border:1px solid #f4403f;}
.clients .clients-slider .swiper-pagination .swiper-pagination-bullet-active {
background-color:#f4403f;}
.recent-blog-posts .post-box {
box-shadow:0px 0 30px rgba(1,41,112,0.08);
    transition:0.3s;
    height:100%;
    overflow:hidden;
    padding:30px 30px 10px;
    border-radius:8px;
    position:relative;
    display:flex;
    flex-direction:column;
    margin:10px;
    background:#fff;}
.recent-blog-posts .post-box .post-img {
overflow:hidden;
    margin:-30px -30px 15px -30px;
    position:relative;}
.recent-blog-posts .post-box .post-img img {
transition:0.5s;}
.recent-blog-posts .post-box .post-date {
font-size:14px;
    font-weight:600;
    color:#000;
    display:block;
    margin-bottom:10px;}
.recent-blog-posts .post-box .post-title {
font-size:20px;
    color:#F4511E;
    font-weight:700;
    margin-bottom:18px;
    position:relative;
    transition:0.3s;}
.recent-blog-posts .post-box .readmore {
display:flex;
    align-items:center;
    font-weight:600;
    line-height:1;
    transition:0.3s;}
.recent-blog-posts .post-box .readmore i {
line-height:0;
    margin-left:4px;
    font-size:18px;}
.recent-blog-posts .post-box:hover .post-title {
color:#f4403f;}
.recent-blog-posts .post-box:hover .post-img img {}
.contact .info-box {
color:#fff;
    padding:30px;}
.contact .info-box i {
font-size:60px;
    line-height:0;
    color:#ce1414;}
.contact .info-box h3 {
font-size:25px;
    color:#ffffff;
    font-weight:700;
    margin:20px 0 10px 0;}
.contact .info-box p {
padding:0;
    line-height:24px;
    font-size:14px;
    margin-bottom:0;}
.contact .php-email-form {
padding:30px;
    height:100%;}
.form-control {
display:block;
    width:100%;
    padding:0.375rem 0.75rem;
    font-size:1rem;
    font-weight:400;
    line-height:1.5;
    color:#FFFFFF;
    background-clip:padding-box;}
.contact .info-box,.contact .php-email-form,.form-control {
background:#766f6f12;
    border:1px solid #07070714;}
.contact .php-email-form .error-message {
display:none;
    color:#fff;
    background:#ed3c0d;
    text-align:left;
    padding:15px;
    margin-bottom:24px;
    font-weight:600;}
.contact .php-email-form .sent-message {
display:none;
    color:#fff;
    background:#18d26e;
    text-align:center;
    padding:15px;
    margin-bottom:24px;
    font-weight:600;}
.contact .php-email-form .loading {
display:none;
    background:#fff;
    text-align:center;
    padding:15px;
    margin-bottom:24px;}
.contact .php-email-form .loading:before {
content:"";
    display:inline-block;
    border-radius:50%;
    width:24px;
    height:24px;
    margin:0 10px -6px 0;
    border:3px solid #18d26e;
    border-top-color:#eee;
    animation:animate-loading 1s linear infinite;}
.contact .php-email-form input,.contact .php-email-form textarea {
border-radius:0;
    box-shadow:none;
    font-size:16px;
    border-radius:20px;}
.contact .php-email-form .submitbtn {
box-shadow:1px 2px 10px gray;
    background:#f4403f;
    font-size:20px;}
.contact .php-email-form input:focus,.contact .php-email-form textarea:focus {
border-color:#f4403f;}
.contact .php-email-form input {
padding:15px 15px;}
.contact .php-email-form textarea {
padding:12px 15px;
    height:80px;}
.contact .php-email-form .submitbtn:hover,.contact .php-email-form .submitbtn:focus {
opacity:.9;}
.contact .php-email-form button[type=submit] {
background:#f4403f;
    border:0;
    padding:10px 30px;
    color:#fff;
    transition:0.4s;
    border-radius:4px;}
.contact .php-email-form button[type=submit]:hover {
background:#5969f3;}
@keyframes animate-loading {
0% {
transform:rotate(0deg);}
100% {
transform:rotate(360deg);}}
.blog {
padding:40px 0 20px 0;}
.blog .entry {
padding:30px;
    margin-bottom:60px;
    box-shadow:0 4px 16px rgba(0,0,0,0.1);}
.blog .entry .entry-img {
max-height:440px;
    margin:-30px -30px 20px -30px;
    overflow:hidden;}
.blog .entry .entry-title {
font-size:28px;
    font-weight:bold;
    padding:0;
    margin:0 0 20px 0;}
.blog .entry .entry-title a {
color:#d7d7d7;
    transition:0.3s;}
.blog .entry .entry-title a:hover {
color:#f4403f;}
.blog .entry .entry-meta {
margin-bottom:15px;
    color:#4084fd;}
.blog .entry .entry-meta ul {
display:flex;
    flex-wrap:wrap;
    list-style:none;
    align-items:center;
    padding:0;
    margin:0;}
.blog .entry .entry-meta ul li+li {
padding-left:20px;}
.blog .entry .entry-meta i {
font-size:16px;
    margin-right:8px;
    line-height:0;}
.blog .entry .entry-meta a {
color:#777777;
    font-size:14px;
    display:inline-block;
    line-height:1;}
.blog .entry .entry-content p {
line-height:24px;}
.blog .entry .entry-content .read-more {
-moz-text-align-last:right;
    text-align-last:right;}
.blog .entry .entry-content .read-more a {
display:inline-block;
    background:#f4403f;
    color:#fff;
    padding:6px 20px;
    transition:0.3s;
    font-size:14px;
    border-radius:4px;}
.blog .entry .entry-content .read-more a:hover {
background:#5969f3;}
.blog .entry .entry-content h3 {
font-size:22px;
    margin-top:30px;
    font-weight:bold;}
.blog .entry .entry-content blockquote {
overflow:hidden;
    background-color:#fafafa;
    padding:60px;
    position:relative;
    text-align:center;
    margin:20px 0;}
.blog .entry .entry-content blockquote p {
color:#444444;
    line-height:1.6;
    margin-bottom:0;
    font-style:italic;
    font-weight:500;
    font-size:22px;}
.blog .entry .entry-content blockquote::after {
content:"";
    position:absolute;
    left:0;
    top:0;
    bottom:0;
    width:3px;
    background-color:#d7d7d7;
    margin-top:20px;
    margin-bottom:20px;}
.blog .entry .entry-footer {
padding-top:10px;
    border-top:1px solid #e6e6e6;}
.blog .entry .entry-footer i {
color:#0d64fd;
    display:inline;}
.blog .entry .entry-footer a {
color:#d7d7d7;
    transition:0.3s;}
.blog .entry .entry-footer a:hover {
color:#f4403f;}
.blog .entry .entry-footer .cats {
list-style:none;
    display:inline;
    padding:0 20px 0 0;
    font-size:14px;}
.blog .entry .entry-footer .cats li {
display:inline-block;}
.blog .entry .entry-footer .tags {
list-style:none;
    display:inline;
    padding:0;
    font-size:14px;}
.blog .entry .entry-footer .tags li {
display:inline-block;}
.blog .entry .entry-footer .tags li+li::before {
padding-right:6px;
    color:#6c757d;
    content:",";}
.accordion .accordion-item .accordion-header {
padding-top:0px;}
.blog .entry .entry-footer .share {
font-size:16px;}
.blog .entry .entry-footer .share i {
padding-left:5px;}
.blog .entry-single {
margin-bottom:30px;}
.blog .blog-author {
padding:20px;
    margin-bottom:30px;
    box-shadow:0 4px 16px rgba(0,0,0,0.1);}
.blog .blog-author img {
width:120px;
    margin-right:20px;}
.blog .blog-author h4 {
font-weight:600;
    font-size:22px;
    margin-bottom:0px;
    padding:0;
    color:#d7d7d7;}
.blog .blog-author .social-links {
margin:0 10px 10px 0;}
.blog .blog-author .social-links a {
color:rgba(1,41,112,0.5);
    margin-right:5px;}
.blog .blog-author p {
font-style:italic;
    color:#b7b7b7;}
.blog .blog-comments {
margin-bottom:30px;}
.blog .blog-comments .comments-count {
font-weight:bold;}
.blog .blog-comments .comment {
margin-top:30px;
    position:relative;}
.blog .blog-comments .comment .comment-img {
margin-right:14px;}
.blog .blog-comments .comment .comment-img img {
width:60px;}
.blog .blog-comments .comment h5 {
font-size:16px;
    margin-bottom:2px;}
.blog .blog-comments .comment h5 a {
font-weight:bold;
    color:#444444;
    transition:0.3s;}
.blog .blog-comments .comment h5 a:hover {
color:#f4403f;}
.blog .blog-comments .comment h5 .reply {
padding-left:10px;
    color:#d7d7d7;}
.blog .blog-comments .comment h5 .reply i {
font-size:20px;}
.blog .blog-comments .comment time {
display:block;
    font-size:14px;
    color:#013ca3;
    margin-bottom:5px;}
.blog .blog-comments .comment.comment-reply {
padding-left:40px;}
.blog .blog-comments .reply-form {
margin-top:30px;
    padding:30px;
    box-shadow:0 4px 16px rgba(0,0,0,0.1);}
.blog .blog-comments .reply-form h4 {
font-weight:bold;
    font-size:22px;}
.blog .blog-comments .reply-form p {
font-size:14px;}
.blog .blog-comments .reply-form input {
border-radius:4px;
    padding:10px 10px;
    font-size:14px;}
.blog .blog-comments .reply-form input:focus {
box-shadow:none;
    border-color:#a0aaf8;}
.blog .blog-comments .reply-form textarea {
border-radius:4px;
    padding:10px 10px;
    font-size:14px;}
.blog .blog-comments .reply-form textarea:focus {
box-shadow:none;
    border-color:#a0aaf8;}
.blog .blog-comments .reply-form .form-group {
margin-bottom:25px;}
.blog .blog-comments .reply-form .btn-primary {
border-radius:4px;
    padding:10px 20px;
    border:0;
    background-color:#d7d7d7;}
.blog .blog-comments .reply-form .btn-primary:hover {
background-color:#d7d7d7;}
.blog .blog-pagination {
color:#024ed5;}
.blog .blog-pagination ul {
display:flex;
    padding:0;
    margin:0;
    list-style:none;}
.blog .blog-pagination li {
margin:0 5px;
    transition:0.3s;}
.blog .blog-pagination li a {
color:#d7d7d7;
    padding:7px 16px;
    display:flex;
    align-items:center;
    justify-content:center;}
.blog .blog-pagination li.active,.blog .blog-pagination li:hover {
background:#f4403f;}
.blog .blog-pagination li.active a,.blog .blog-pagination li:hover a {
color:#fff;}
.blog .sidebar {
padding:30px;
    margin:0 0 60px 20px;
    box-shadow:0 4px 16px rgba(0,0,0,0.1);}
.blog .sidebar .sidebar-title {
font-size:20px;
    font-weight:700;
    padding:0 0 0 0;
    margin:0 0 15px 0;
    color:#d7d7d7;
    position:relative;}
.blog .sidebar .sidebar-item {
margin-bottom:30px;}
.blog .sidebar .search-form form {
background:#fff;
    border:1px solid #ddd;
    padding:3px 10px;
    position:relative;}
.blog .sidebar .search-form form input[type=text] {
border:0;
    padding:4px;
    border-radius:4px;
    width:calc(100% - 40px);}
.blog .sidebar .search-form form button {
position:absolute;
    top:0;
    right:0;
    bottom:0;
    border:0;
    background:none;
    font-size:16px;
    padding:0 15px;
    margin:-1px;
    background:#f4403f;
    color:#fff;
    transition:0.3s;
    border-radius:0 4px 4px 0;
    line-height:0;}
.blog .sidebar .search-form form button i {
line-height:0;}
.blog .sidebar .search-form form button:hover {
background:#5465f2;}
.blog .sidebar .categories ul {
list-style:none;
    padding:0;}
.blog .sidebar .categories ul li+li {
padding-top:10px;}
.blog .sidebar .categories ul a {
color:#d7d7d7;
    transition:0.3s;}
.blog .sidebar .categories ul a:hover {
color:#f4403f;}
.blog .sidebar .categories ul a span {
padding-left:5px;
    color:#aaaaaa;
    font-size:14px;}
.blog .sidebar .recent-posts .post-item+.post-item {
margin-top:15px;}
.blog .sidebar .recent-posts img {
width:80px;
    float:left;}
.blog .sidebar .recent-posts h4 {
font-size:15px;
    margin-left:95px;
    font-weight:bold;}
.blog .sidebar .recent-posts h4 a {
color:#d7d7d7;
    transition:0.3s;}
.blog .sidebar .recent-posts h4 a:hover {
color:#f4403f;}
.blog .sidebar .recent-posts time {
display:block;
    margin-left:95px;
    font-style:italic;
    font-size:14px;
    color:#aaaaaa;}
.blog .sidebar .tags {
margin-bottom:-10px;}
.blog .sidebar .tags ul {
list-style:none;
    padding:0;}
.blog .sidebar .tags ul li {
display:inline-block;}
.blog .sidebar .tags ul a {
color:#0257ee;
    font-size:14px;
    padding:6px 14px;
    margin:0 6px 8px 0;
    border:1px solid #d7e6ff;
    display:inline-block;
    transition:0.3s;}
.blog .sidebar .tags ul a:hover {
color:#fff;
    border:1px solid #f4403f;
    background:#f4403f;}
.blog .sidebar .tags ul a span {
padding-left:5px;
    color:#a5c5fe;
    font-size:14px;}
.footer {
background:#f6f9ff;
    padding:0 0 30px 0;
    font-size:14px;}
.footer .footer-newsletter {
padding:50px 0;}
.footer .footer-newsletter h4 {
font-size:24px;
    margin:0 0 10px 0;
    padding:0;
    line-height:1;
    font-weight:700;
    color:#d7d7d7;}
.footer .footer-newsletter form {
margin-top:20px;
    background:#fff;
    padding:15px 10px;
    position:relative;
    border-radius:4px;
    border:1px solid #e1ecff;}
.footer .footer-newsletter form input[type=email] {
border:0;
    padding:8px;
    width:calc(100% - 140px);}
.footer .footer-newsletter form input[type=submit] {
position:absolute;
    top:0;
    right:0;
    bottom:0;
    border:0;
    background:none;
    font-size:16px;
    padding:0 30px;
    margin:3px;
    background:#be3838;
    color:#fff;
    transition:0.3s;
    border-radius:4px;}
.footer .footer-newsletter form input[type=submit]:hover {
background:#c22827;}
.footer .footer-top {
background-size:contain;
    padding:60px 0 30px 0;}
@media (max-width:992px) {
.footer .footer-top {
background-position:center bottom;}}
.footer .footer-top .footer-info {
margin-bottom:30px;}
.footer .footer-top .footer-info .logo {
line-height:0;
    margin-bottom:15px;}
.footer .footer-top .footer-info .logo img {
max-height:40px;
    margin-right:6px;}
.footer .footer-top .footer-info .logo span {
font-size:30px;
    font-weight:700;
    letter-spacing:1px;
    color:#d7d7d7;
    font-family:"Nunito",sans-serif;
    margin-top:3px;}
.footer .footer-top .footer-info p {
font-size:14px;
    line-height:24px;
    margin-bottom:0;
    font-family:"Nunito",sans-serif;}
.footer .footer-top .social-links a {
font-size:20px;
    display:inline-block;
    color:rgb(244 64 63);
    line-height:0;
    margin-right:10px;
    transition:0.3s;}
.footer .footer-top .social-links a:hover {
color:#d7d7d7;}
.footer .footer-top h4 {
font-size:16px;
    font-weight:bold;
    color:#d7d7d7;
    text-transform:uppercase;
    position:relative;
    padding-bottom:12px;}
.footer .footer-top .footer-links {
margin-bottom:30px;}
.footer .footer-top .footer-links ul {
list-style:none;
    padding:0;
    margin:0;}
.footer .footer-top .footer-links ul i {
padding-right:2px;
    color:#d7d7d7;
    font-size:12px;
    line-height:0;}
.footer .footer-top .footer-links ul li {
padding:10px 0;
    display:flex;
    align-items:center;}
.footer .footer-top .footer-links ul li:first-child {
padding-top:0;}
.footer .footer-top .footer-links ul a {
color:#d7d7d7;
    transition:0.3s;
    display:inline-block;
    line-height:1;}
.footer .footer-top .footer-links ul a:hover {
color:#f4403f;}
.footer .footer-top .footer-contact p {
line-height:26px;}
.footer .copyright {
text-align:center;
    padding-top:30px;
    color:#d7d7d7;}
.footer .credits {
padding-top:10px;
    text-align:center;
    font-size:13px;
    color:#d7d7d7;}
.hiddenclasss {
display:none;
    visibility:hidden;}
.contact .info-box p a,.contact a {
color:#fff;}
#values {
background:#0c0c0c;
    background-size:cover;
    color:#8d8d8d;}
#features {
background:#0f1429;
    background-size:cover;
    color:#d7d7d7;}
#features2 {
background:#171717;
    color:#d7d7d7;
    padding-top:30px;}
#features3 {
background:#040e24;
    color:#d7d7d7;}
.accordion {
color:#d7d7d7;}
#about {
background:#0f0f0f;
    background-size:cover;
    color:#d7d7d7;
    transition:background 0.5s ease;}
#about:hover {
animation:rgbBackground 10s infinite alternate;
    color:#fff;
    transition:background 5s ease;}
@keyframes rgbBackground {
0% {
background:linear-gradient(135deg,#2e0249,#1b003b);}
50% {
background:linear-gradient(135deg,#0a1f29,#1b2731,#2c3946);}
100% {
background:linear-gradient(135deg,#0f2027,#203a43,#2c5364);}}
#pageContent {
background:#0f0f0f;
    background-size:cover;
    color:#d7d7d7;
    transition:background 0.5s ease;}
#pageContent:hover {
animation:rgbBackground 10s infinite alternate;
    color:#fff;
    transition:background 5s ease;}
@keyframes rgbBackground {
0% {
background:linear-gradient(135deg,#2e0249,#1b003b);}
50% {
background:linear-gradient(135deg,#0a1f29,#1b2731,#2c3946);}
100% {
background:linear-gradient(135deg,#0f2027,#203a43,#2c5364);}}
#portfolio {
background:#000;
    background-size:cover;
    color:#000;}
#testimonials {
background:linear-gradient(45deg,#080c3a,#000000);
    color:#fff;}
#recent-blog-posts {
background:#090909;
    color:#fff;}
#contact {
background:#0f0f0f;
    color:#fff;}
#services {
background:#000;
    background-size:contain;
    color:#fff;}
#about {
background:linear-gradient(135deg,#0a0a0a,#1d1d1d,#333333,#404040,#2c2c2c,#262626,#3f3f3f,#292929,#232323,#181818);
    background-size:400% 400%;
    animation:gradientShift 15s ease-in-out infinite;}
#values {
background:linear-gradient(135deg,#121212,#1e1e1e,#2c2c2c,#3b3b3b,#262626,#353535,#4a4a4a,#474747,#393939,#232323);
    background-size:400% 400%;
    animation:gradientShift 17s ease-in-out infinite;}
#count {
background:linear-gradient(135deg,#003333,#004141,#004848,#005050,#010101,#2f2f2f,#383838,#4a4a4a,#2c2c2c,#101010);
    background-size:400% 400%;
    animation:gradientShift 18s ease-in-out infinite;}
#features {
background:linear-gradient(135deg,#2b0a2b,#3a003a,#520352,#6a056a,#0b0b0b,#7b1e7b,#8e2a8e,#5a195a,#3a0e3a,#2f0e2f);
    background-size:400% 400%;
    animation:gradientShift 20s ease-in-out infinite;}
#features2 {
background:linear-gradient(135deg,#0f0f0f,#1a1a1a,#292929,#353535,#0b0b0b,#2d2d2d,#444444,#3e3e3e,#313131,#181818);
    background-size:400% 400%;
    animation:gradientShift 18s ease-in-out infinite;}
#features3 {
background:linear-gradient(135deg,#021f1f,#043434,#054e4e,#065959,#0c0c0c,#1d3f3f,#2e6363,#3f7979,#4f8e8e,#3c6565);
    background-size:400% 400%;
    animation:gradientShift 22s ease-in-out infinite;}
#faq {
background:linear-gradient(135deg,#0e2233,#1a2d45,#2b3e58,#3b4f6a,#0b0b0b,#2e4861,#3f6075,#4f7187,#5f8b9a,#3d5e74);
    background-size:400% 400%;
    animation:gradientShift 16s ease-in-out infinite;}
#portfolio {
background:linear-gradient(135deg,#011d2e,#023440,#023b52,#02465d,#020202,#1e3243,#2a3f5a,#37566f,#4b6f7e,#2d4d63);
    background-size:400% 400%;
    animation:gradientShift 25s ease-in-out infinite;}
#testimonials {
background:linear-gradient(135deg,#670000,#7d1f1f,#6e1a1a,#5a1414,#0d0d0d,#9f3030,#731e1e,#5e1616,#471313,#3a0f0f);
    background-size:400% 400%;
    animation:gradientShift 20s ease-in-out infinite;}
#recent-blog-posts {
background:linear-gradient(135deg,#0f0f0f,#1d1d1d,#292929,#353535,#0c0c0c,#262626,#404040,#555555,#404040,#0b0b0b);
    background-size:400% 400%;
    animation:gradientShift 15s ease-in-out infinite;}
#contact {
background:linear-gradient(135deg,#1b3b4e,#264756,#2d5970,#357a82,#010101,#275e72,#3a7587,#4d8d99,#5b9caa,#41778f);
    background-size:400% 400%;
    animation:gradientShift 18s ease-in-out infinite;}
#services {
background:linear-gradient(135deg,#00314f,#004c68,#00617e,#007894,#020202,#2c5d7d,#3b6f89,#4c8095,#5d94a0,#417085);
    background-size:400% 400%;
    animation:gradientShift 18s ease-in-out infinite;}
#footer {
background:linear-gradient(135deg,#0c0c0c,#151515,#1c1c1c,#252525,#0a0a0a,#131313,#1e1e1e,#292929,#353535,#1e1e1e);
    background-size:400% 400%;
    animation:gradientShift 25s ease-in-out infinite;}
@keyframes gradientShift {
0% {
background:linear-gradient(135deg,#1f2c36,#30444e,#40515f);
    filter:contrast(1.3);}
50% {
background:linear-gradient(135deg,#0a1f29,#1b2731,#2c3946);
    filter:contrast(1.6);}
100% {
background:linear-gradient(135deg,#1f2c36,#30444e,#40515f);
    filter:contrast(1.3);}}
#about .img-fluid {
border-radius:30px;
    border:2px solid #f4403f;}
.features,.features h3,.features h4,.features ul li a,.features p {
color:#ececec;}
.contact .php-email-form input:focus,.contact .php-email-form textarea:focus {
background:#07070714;
    border:1px solid #098579;}
.contact .info-box h3,.contact .info-box p a,.contact .php-email-form input,.contact .info-box p {
color:#eaeeea}
.services .service-box p {
text-align:justify;}
::placeholder {
color:#eaeeea !important;
    opacity:1;}
:-ms-input-placeholder {
color:#eaeeea !important;}
::-ms-input-placeholder {
color:#eaeeea !important;}
.fixedabove {
position:relative;
    overflow:hidden;
    color:#fff;}
.fixedabove video {
min-width:100%;
    min-height:100%;
    width:auto;
    height:auto;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    margin:0;
    padding:0;}
.mainsliderbg {
background-image:linear-gradient(to bottom right,#000000,#000);
    opacity:.9;
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    width:100%;
    height:100%;
    margin:0;
    box-sizing:border-box;
    padding:2% 32px;
    line-height:1.5;
    text-align:center;}
.homepg .mainsliderbg {
background-image:linear-gradient(to bottom right,#000000bd,#000000d6);
    opacity:1;}
.mainsliderbg:last-child {
margin-bottom:0;}
.fixedabove p,.fixedabove a,.fixedabove h1,.fixedabove h2 {
color:#fff;}
.typing_erase {
color:#000;
    width:0;
    max-width:max-content;
    overflow:hidden;
    border-right:0.05em solid #464545;
    white-space:nowrap;
    margin:0;
    display:inline-block;
    text-transform:uppercase;
    font-family:verdana;
    font-size:5em !important;
    font-weight:700;
    color:#f5f5f5;
    text-shadow:1px 1px 1px #919191,1px 2px 1px #919191,1px 3px 1px #919191,1px 4px 1px #919191,1px 5px 1px #919191,1px 6px 1px #919191,1px 7px 1px #919191,1px 8px 1px #919191,1px 9px 1px #919191,1px 10px 1px #919191,1px 18px 6px rgba(16,16,16,0.4),1px 22px 10px rgba(16,16,16,0.2),1px 25px 35px rgba(16,16,16,0.2),1px 30px 60px rgba(16,16,16,0.4);}
.typing_erase {
animation:5s typing_erase 1s steps(50,end) infinite,blink_caret .9s step-end infinite;}
@keyframes typing_erase {
0% {
width:0}
80% {
width:100%}
90%,100% {
width:0}}
@keyframes blink_caret {
from,to {
border-color:transparent}
50% {
border-color:gray}}
.pic_designs {
width:200px;
    height:auto;
    animation:movings 5s infinite;
    -webkit-animation:movings 5s infinite}
@keyframes movings {
0% {
-webkit-transform:rotate(-1deg) translate(-1px,-1px);
    transform:rotate(-1deg) translate(-1px,-1px)}
50% {
-webkit-transform:rotate(1deg) translate(2px,2px);
    transform:rotate(1deg) translate(2px,2px)}
to {
-webkit-transform:rotate(-1deg) translate(-1px,-1px);
    transform:rotate(-1deg) translate(-1px,-1px)}}
.rotate_imgs {
animation:rotatebackward 500ms 1 ease;}
.rotate_imgs:hover {
animation:rotateforward 500ms 1 ease forwards;}
@keyframes rotatebackward {
0% {
-webkit-transform:rotate(360deg) scale(1.0);}
100% {
-webkit-transform:rotate(0deg) scale(1.0);}}
@keyframes rotateforward {
0% {
-webkit-transform:rotate(0deg) scale(1.0);}
100% {
-webkit-transform:rotate(360deg) scale(1.0);}}
@media only screen and (min-width:500px) {
.p {
font-size:3rem;}}
@media only screen and (min-width:700px) {
.p {
font-size:5rem;}}
.p1 {
text-shadow:1px 1px 1px black,-1px -1px 1px black;
    color:white;}
.p2 {
color:#ffc107;
    clip-path:polygon(0 0,100% 0,100% 100%,0% 100%);
    animation:text-animation 3s ease-in-out forwards alternate infinite;
    text-shadow:1px 1px 1px black,-1px -1px 1px black;}
.p3 {
height:20%;
    color:transparent;}
@keyframes text-animation {
from {
clip-path:polygon(0 0,100% 0,100% 100%,0% 100%);}
to {
clip-path:polygon(0 0,0% 1%,0% 100%,0% 100%);}}
.cursor {
position:absolute;
    top:50%;
    height:100%;
    width:100%;
    transform:translate(0,-45%);
    border-right:2px solid #ffc107;
    animation:cursor-animation 3s ease-in-out forwards alternate infinite;}
@keyframes cursor-animation {
from {
width:100%;}
to {
width:0;}}
.web_ment_loader {
perspective:1000px;
    -webkit-perspective:1000px;}
.web_ment_loader > span {
display:inline-block;
    color:#fff;
    transform-style:preserve-3d;
    transform-origin:0 100%;
    animation:anim 3s infinite linear;
    font-size:6em;
    margin:0 auto 0 auto;
    font-family:'Lato',sans-serif;
    line-height:80px;
    font-size:3.5rem;
    text-align:center;
    text-transform:uppercase;
    text-rendering:optimizeLegibility;
    color:#70869d;
    letter-spacing:.15em;
    text-shadow:-1px -1px 1px #efede3,0px 1px 0 #2e2e2e,0px 2px 0 #2c2c2c,0px 3px 0 #2a2a2a,0px 4px 0 #282828,0px 5px 0 #262626,0px 6px 0 #242424,0px 7px 0 #222,0px 8px 0 #202020,0px 9px 0 #1e1e1e,0px 10px 0 #1c1c1c,0px 11px 0 #1a1a1a,0px 12px 0 #181818,0px 13px 0 #161616,0px 14px 0 #141414,0px 15px 0 #121212,2px 20px 5px rgba(0,0,0,0.9),5px 23px 5px rgba(0,0,0,0.3),8px 27px 8px rgba(0,0,0,0.5),8px 28px 35px rgba(0,0,0,0.9);}
.web_ment_loader > span:nth-child(even) {
color:gray;}
.web_ment_loader > span:nth-child(2) {
animation-delay:0.2s;}
.web_ment_loader > span:nth-child(3) {
animation-delay:0.4s;}
.web_ment_loader > span:nth-child(4) {
animation-delay:0.6s;}
.web_ment_loader > span:nth-child(5) {
animation-delay:0.8s;}
.web_ment_loader > span:nth-child(6) {
animation-delay:1s;}
.web_ment_loader > span:nth-child(7) {
animation-delay:1.2s;}
.web_ment_loader > span:nth-child(8) {
animation-delay:1.4s;}
.web_ment_loader > span:nth-child(9) {
animation-delay:1.6s;}
.web_ment_loader > span:nth-child(10) {
animation-delay:1.8s;}
.web_ment_loader > span:nth-child(11) {
animation-delay:2s;}
.web_ment_loader > span:nth-child(12) {
animation-delay:2.2s;}
.web_ment_loader > span:nth-child(13) {
animation-delay:2.4s;}
.web_ment_loader > span:nth-child(14) {
animation-delay:2.6s;}
.web_ment_loader > span:nth-child(15) {
animation-delay:2.8s;}
.web_ment_loader > span:nth-child(16) {
animation-delay:2.10s;}
.web_ment_loader > span:nth-child(18) {
animation-delay:2.12s;}
@keyframes anim {
35% {
transform:rotateX(360deg);}
100% {
transform:rotateX(360deg);}}
.title-word {
animation:color-animation 4s linear infinite;
    font-size:40px;}
.title-word-1 {
--color-1:#DF8453;
    --color-2:#3D8DAE;
    --color-3:#E4A9A8;}
.title-word-2 {
--color-1:#DBAD4A;
    --color-2:#ACCFCB;
    --color-3:#17494D;}
.title-word-3 {
--color-1:#ACCFCB;
    --color-2:#E4A9A8;
    --color-3:#ACCFCB;}
.title-word-4 {
--color-1:#3D8DAE;
    --color-2:#DF8453;
    --color-3:#E4A9A8;}
@keyframes color-animation {
0% {
color:var(--color-1)}
32% {
color:var(--color-1)}
33% {
color:var(--color-2)}
65% {
color:var(--color-2)}
66% {
color:var(--color-3)}
99% {
color:var(--color-3)}
100% {
color:var(--color-1)}}
.waviy {
position:relative;
    -webkit-box-reflect:below -20px linear-gradient(transparent,rgba(0,0,0,.2));
    font-size:60px;}
.waviy span {
font-family:'Alfa Slab One',cursive;
    position:relative;
    display:inline-block;
    color:#fff;
    text-transform:uppercase;
    animation:waviy 1s infinite;
    animation-delay:calc(.1s * var(--i));}
@keyframes waviy {
0%,40%,100% {
transform:translateY(0)}
20% {
transform:translateY(-20px)}}
#text3d::before {
content:"";
    width:100%;
    height:750px;
    position:absolute;
    top:-200px;
    left:10px;
    transform:rotate(55deg);
    background:rgba(206,188,155,.7);
    background:-moz-linear-gradient(left,rgba(206,188,155,.7) 0%,rgba(42,31,25,0) 65%);
    background:-webkit-gradient(left top,right top,color-stop(0%,rgba(206,188,155,.7)),color-stop(65%,rgba(42,31,25,0)));
    background:-webkit-linear-gradient(left,rgba(206,188,155,.7) 0%,rgba(42,31,25,0) 65%);
    background:-o-linear-gradient(left,rgba(206,188,155,.7) 0%,rgba(42,31,25,0) 65%);
    background:-ms-linear-gradient(left,rgba(206,188,155,.7) 0%,rgba(42,31,25,0) 65%);
    background:linear-gradient(to right,rgb(75 70 60 / 70%) 0%,rgba(42,31,25,0) 65%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#fad59f',endColorstr='#fa9907');
    z-index:-1;}
#text3d {
width:100%;
    margin:0 auto 0 auto;
    font-family:'Lato',sans-serif;
    line-height:70px;
    font-size:4.2rem;
    padding:10px 0px;
    text-align:center;
    text-transform:uppercase;
    text-rendering:optimizeLegibility;
    color:#b9c4d0;
    letter-spacing:.15em;
    text-shadow:-1px -1px 1px #efede3,0px 1px 0 #2e2e2e,0px 2px 0 #2c2c2c,0px 3px 0 #2a2a2a,0px 4px 0 #282828,0px 5px 0 #262626,0px 6px 0 #242424,0px 5px 0 #222,0px 5px 0 #202020,0px 5px 0 #1e1e1e,0px 5px 0 #1c1c1c,0px 1px 0 #1a1a1a,0px 2px 0 #181818,0px 3px 0 #161616,0px 4px 0 #141414,0px 5px 0 #121212,2px 4px 5px rgba(0,0,0,0.9),5px 3px 5px rgba(0,0,0,0.3),8px 7px 8px rgba(0,0,0,0.5),8px 2px 5px rgba(0,0,0,0.9);
    text-align:left !important;}
.box_inners {
display:flex;}
.box_inners .inner {
width:400px;
    height:200px;
    line-height:200px;
    font-size:4em;
    font-family:sans-serif;
    font-weight:bold;
    white-space:nowrap;
    overflow:hidden;}
.box_inners .inner:first-child {
background-color:indianred;
    color:darkred;
    transform-origin:right;
    transform:perspective(100px) rotateY(-15deg);}
.box_inners .inner:last-child {
background-color:lightcoral;
    color:antiquewhite;
    transform-origin:left;
    transform:perspective(100px) rotateY(15deg);}
.box_inners .inner span {
position:absolute;
    animation:marquee 5s linear infinite;}
.box_inners .inner:first-child span {
animation-delay:2.5s;
    left:-100%;}
@keyframes marquee {
from {
left:100%;}
to {
left:-100%;}}
#hero .homedes {
margin-top:15%;
    width:150px;}
#hero .campaignpage .imgsz {
width:100px;
    margin-top:15%;}
.campaignpage #text3d {
font-size:3rem;
    ;
    text-shadow:1px 2px 5px #222;}
.campaignpage form {
margin-top:20px;
    margin-bottom:10px;}
.campaignpage .claimnow .tcst {
font-size:9px;}
.campaignpage .claimnow {
background:linear-gradient(45deg,#720e0ea8,transparent);
    border-radius:5px;
    padding:12px 20px;
    margin-bottom:30px;
    text-align:left;
    font-size:1.3em;}
.campaignpage input,.campaignpage input:focus,.campaignpage input:hover {
padding:20px 10px;
    box-shadow:1px 1px 7px #222;
    background:#222;
    color:#fff}
.campaignpage .submitbtn {
background:#43a047;
    color:#fff;
    font-size:16px;
    letter-spacing:2px;
    ;
    font-weight:500;}
.campaignpage .submitbtn:focus,.campaignpage .submitbtn:active {
background:#43a047;}
.campaignpage .submitbtn:hover {
background:#2d7a30;}
.footer-newsletter form input:focus,.footer-newsletter form input:active {
outline:0;
    border:0;}
.footer-newsletter form input:-webkit-autofill,.footer-newsletter form input:-webkit-autofill:hover,.footer-newsletter form input:-webkit-autofill:focus,.footer-newsletter form input:-webkit-autofill:active {
-webkit-background-clip:text;
    -webkit-text-fill-color:#000;
    transition:background-color 5000s ease-in-out 0s;
    box-shadow:inset 0 0 20px 20px #fff;}
.multiple_effects {
font-weight:700;}
.multiple_effects span::before {
content:'';
    color:#e33b3b;
    animation:profile 5s infinite;}
@keyframes profile {
0% {
content:'Designer'}
100% {
content:'Developer'}}
.multiple_effects .offers::before {
content:'';
    color:#fba330;
    animation:offers 5s infinite;
    -webkit-text-fill-color:transparent;
    background:yellow;
    -webkit-background-clip:text;
    background-clip:text;}
@keyframes offers {
0% {
content:'Today Only Deals'}
50% {
content:'Upto 80% OFF*'}
100% {
content:'Hurry Up'}}
.hero .headerdesign,.content_sec {
font-size:16px;
    font-weight:500;
    text-align:left;
    color:#bcbcbc;
    display:inline;
    text-align:justify;}
#hero .fzsml {
font-size:2.5em;
    line-height:44px;}
#hero .imgsz {
width:150px;
    margin-top:25%;}
.multieffecctss {
color:#333;
    font-family:tahoma;
    font-size:3rem;
    font-weight:100;
    line-height:1.5;
    text-transform:uppercase;
    white-space:nowrap;
    overflow:hidden;
    position:relative;
    width:850px;}
.multieffecctss span {
font-size:40px;
    margin-left:0px;
    text-align:left;}
.message {
background-color:green;
    color:#fff;
    display:block;
    font-weight:900;
    overflow:hidden;
    position:absolute;
    padding-left:0.5rem;
    top:0.2rem;
    left:270px;
    animation:openclose 5s ease-in-out infinite;}
.word1,.word2,.word3 {
font-family:tahoma;}
@keyframes openclose {
0% {
top:0.2rem;
    width:0;}
5% {
width:0;}
15% {
width:230px;}
30% {
top:0.2rem;
    width:230px;}
33% {
top:0.2rem;
    width:0;}
35% {
top:0.2rem;
    width:0;}
38% {
top:-4.5rem;}
48% {
top:-4.5rem;
    width:190px;}
62% {
top:-4.5rem;
    width:190px;}
66% {
top:-4.5rem;
    width:0;
    text-indent:0;}
71% {
top:-9rem;
    width:0;
    text-indent:5px;}
86% {
top:-9rem;
    width:285px;}
95% {
top:-9rem;
    width:285px;}
98% {
top:-9rem;
    width:0;
    text-indent:5px;}
100% {
top:0;
    width:0;
    text-indent:0;}}
.sideleft {
position:fixed;
    right:0px;
    top:20%;
    width:45px;
    z-index:99999;
    border-right:0px solid #f4403f;}
.sideleft ul {
padding:0px;
    margin:0;}
.sideleft ul li {
list-style:none;}
.sideleft ul li:hover {
filter:drop-shadow(2px 4px 6px black);}
.sideleft ul li a {
text-align:center;
    display:block;
    color:#fff!important;
    font-weight:300;
    font-size:10px;
    text-decoration:none;
    background:rgb(42 42 43 / 87%);
    padding:10px 0;
    border-bottom:.2px solid #575858;}
.sideleft ul li a:hover {
background:#f4403f;}
.sideleft ul li a i {
display:block;
    color:#fff;
    font-size:15px;
    font-weight:100;}
ul {
padding:0px;}
ul {
padding:0px;}
.sideleft ul li:nth-child(1) a {
background:#0077B5;}
.sideleft ul li:nth-child(2) a {
background:#25D366;}
.sideleft ul li:nth-child(3) a {
background:#316FF6;}
.sideleft ul li:nth-child(4) a {
background:linear-gradient(30deg,#f38334,#da2e7d 50%,#6b54c6);}
.sideleft ul li:nth-child(5) a {
background:#cb2027}
.sideleft ul li:nth-child(6) a {
background:#5865f2;}
.sideleft ul li:nth-child(7) a {
background:#161a1f;}
.sideleft ul li:nth-child(8) a {
background:#080808;}
@media (min-width:320px) and (max-width:767px) {
.sideleft {
border-right:2px solid #2196f3;
    width:35px;}
.sideleft ul li {
width:35px;}
.sideleft ul li a {
text-align:center;
    display:block;
    color:#fff!important;
    font-weight:300;
    font-size:5px;
    text-decoration:none;
    background:#5a4e4e54;
    padding:5px 4px;
    border-bottom:0.2px solid #585c5e;}}
.floating_btn {
position:fixed;
    bottom:0px;
    right:0px;
    width:100px;
    height:100px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    z-index:1000;}
.contact_icon {
background-color:#42db87;
    color:#fff;
    width:50px;
    height:50px;
    font-size:30px;
    border-radius:50px;
    text-align:center;
    box-shadow:2px 2px 3px #999;
    display:flex;
    align-items:center;
    justify-content:center;
    transform:translatey(0px);
    animation:pulse 1.5s infinite;
    box-shadow:0 0 0 0 #42db87;
    -webkit-animation:pulsing 1.25s infinite cubic-bezier(0.66,0,0,1);
    -moz-animation:pulsing 1.25s infinite cubic-bezier(0.66,0,0,1);
    -ms-animation:pulsing 1.25s infinite cubic-bezier(0.66,0,0,1);
    animation:pulsing 1.25s infinite cubic-bezier(0.66,0,0,1);
    font-weight:normal;
    font-family:sans-serif;
    text-decoration:none!important;
    transition:all 300ms ease-in-out;
    text-decoration:none!important;}
.floating_btn a:hover {
text-decoration:none!important;}
@-webkit-keyframes pulse {
0% {
-webkit-transform:scaleX(1);
    transform:scaleX(1);}
50% {
-webkit-transform:scale3d(1.05,1.05,1.05);
    transform:scale3d(1.05,1.05,1.05);}
to {
-webkit-transform:scaleX(1);
    transform:scaleX(1);}}
@keyframes pulse {
0% {
-webkit-transform:scaleX(1);
    transform:scaleX(1);}
50% {
-webkit-transform:scale3d(1.05,1.05,1.05);
    transform:scale3d(1.05,1.05,1.05);}
to {
-webkit-transform:scaleX(1);
    transform:scaleX(1);}}
@keyframes pulsing {
to {
box-shadow:0 0 0 30px rgba(232,76,61,0);}}
.wrapper,.wrapper .img-area,.social-icons a,.buttons button {}
.wrapper {
position:relative;}
.wrapper .icon {
font-size:17px;
    color:#31344b;
    position:absolute;
    cursor:pointer;
    opacity:0.7;
    top:15px;
    height:35px;
    width:35px;
    text-align:center;
    line-height:35px;
    border-radius:50%;
    font-size:16px;}
.wrapper .icon i {
position:relative;
    z-index:9;}
.wrapper .icon.arrow {
left:15px;}
.wrapper .icon.dots {
right:15px;}
.wrapper .img-area {
display:flex;
    align-items:center;
    justify-content:center;}
.img-area .inner-area {
height:calc(100% - 25px);
    width:calc(100% - 25px);
    border-radius:50%;}
.inner-area img {
height:100%;
    width:100%;
    object-fit:cover;}
.wrapper .name {
font-size:23px;
    font-weight:500;
    color:#31344b;
    margin:10px 0 5px 0;
    display:none;}
.wrapper .about {
color:#44476a;
    font-weight:400;
    font-size:16px;}
.wrapper .social-icons {
margin:15px 0 25px 0;}
.social-icons a {
position:relative;
    height:40px;
    width:40px;
    margin:0 5px;
    display:inline-flex;
    text-decoration:none;
    border-radius:50%;}
.social-icons a:hover::before,.wrapper .icon:hover::before,.buttons button:hover:before {
content:"";
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    border-radius:50%;
    background:#ecf0f3;
    box-shadow:inset -3px -3px 7px #ffffff,inset 3px 3px 5px #ceced1;}
.buttons button:hover:before {
z-index:-1;
    border-radius:5px;}
.social-icons a i {
position:relative;
    z-index:3;
    text-align:center;
    width:100%;
    height:100%;
    line-height:40px;}
.social-icons a.fb i {
color:#4267B2;}
.social-icons a.twitter i {
color:#1DA1F2;}
.social-icons a.insta i {
color:#E1306C;}
.social-icons a.yt i {
color:#ff0000;}
.wrapper .buttons {
display:flex;
    width:100%;
    justify-content:space-between;}
.buttons button {
position:relative;
    width:100%;
    border:none;
    outline:none;
    padding:12px 0;
    color:#31344b;
    font-size:17px;
    font-weight:400;
    border-radius:5px;
    cursor:pointer;
    z-index:4;
    display:none;}
.buttons button:first-child {
margin-right:10px;}
.buttons button:last-child {
margin-left:10px;}
.wrapper .social-share {
display:flex;
    width:32%;
    margin-top:-66px;
    padding:0 5px;
    justify-content:space-between;
    background:#fff;}
.social-share .row {
color:#31344b;
    font-size:17px;
    cursor:pointer;
    position:relative;}
.social-share .row::before {
position:absolute;
    content:"";
    height:100%;
    width:2px;
    background:#e0e6eb;
    margin-left:-25px;}
.row:first-child::before {
background:none;}
.social-share .row i.icon-2 {
position:absolute;
    left:0;
    top:50%;
    color:#31344b;
    transform:translateY(-50%);
    opacity:0;
    pointer-events:none;
    transition:all 0.3s ease;}
.row:nth-child(1):hover i.fa-heart,.row:nth-child(2):hover i.fa-comment {
opacity:1;
    pointer-events:auto;}
.pageContent h2 {
    font-size: 25px;
    padding-top: 25px;
    color: #fff; /* White color for the text */
    margin-bottom: 5px;
    position: relative;
    padding-left: 70px; /* Space for the creative design */
    font-weight: bold; /* Bold font for emphasis */
}

.pageContent h2::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 12px; /* Width of the first dot */
    height: 12px; /* Height of the first dot */
    background-color: #E74C3C; /* Red color for the dot */
    border-radius: 50%; /* Circular shape */
    transform: translateY(-50%);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.pageContent h2::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 22px; /* Slight offset for the second dot */
    width: 12px;
    height: 12px;
    background-color: #F39C12; /* Orange color for the dot */
    border-radius: 50%;
    transform: translateY(-50%);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}

.pageContent h2::nth-of-type(2)::after {
    left: 40px; /* Offset for additional dots */
    width: 15px;
    height: 15px;
    background-color: #8E44AD; /* Purple color for creativity */
}

.pageContent h2::nth-of-type(3)::after {
    left: 60px;
    width: 18px;
    height: 18px;
    background-color: #3498db; /* Blue color */
}

.pageContent h2::after {
    animation: rotateDots 3s ease-in-out infinite; /* Animation to make dots rotate */
}

@keyframes rotateDots {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.pageContent h3 {
    font-size: 2em;
    padding-top: 30px;
    color: #fff; /* Dark grayish-blue color for the text */
    margin-bottom: 10px;
    position: relative;
    padding-left: 70px; /* Create space for the design */
    font-weight: bold; /* Bold font for emphasis */
}

.pageContent h3::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 50px; /* Width of the design */
    height: 50px; /* Height of the design */
    background-color: #E74C3C; /* Vibrant red color */
    transform: translateY(-50%);
    clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 0% 100%); /* Angular shape */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); /* Adds depth */
    border-radius: 5px; /* Slight rounding for a modern look */
}

.pageContent h4 {
font-size:19px;
    padding-top:25px;
    color:#eee;
    margin-bottom:5px;
    font-weight:600;}
.pageContent .section-header h2 {
font-size:35px;
    padding-top:20px;
    color:#f4403f;
    text-transform:capitalize;
    color:#fff;}
.pageContent ul,.pageContent ol {
display:flex;
    margin:20px auto;
    flex-wrap:wrap;
    padding:0px;}
.pageContent ul li,
.pageContent ol li {
    padding: 20px 30px;
    border: 2px solid #2c2f36;
    list-style: none;
    margin: 1%;
    width: calc(33.33% - 2%);
    text-align: center;
    background: linear-gradient(145deg, #3a3f46, #000);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3), 0 6px 15px rgba(0, 0, 0, 0.2);
    flex: auto;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    position: relative; /* Essential for positioning the pseudo-elements */
}

.pageContent ul li::before,
.pageContent ol li::before,
.pageContent ul li::after,
.pageContent ol li::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    transition: all 0.3s ease-in-out;
}

.pageContent ul li::before,
.pageContent ol li::before {
    border-top: 2px solid #333; /* Thin dark gray top border */
    border-bottom: 2px solid #333; /* Thin dark gray bottom border */
    transform: scaleX(0);
    transform-origin: center;
}

.pageContent ul li::after,
.pageContent ol li::after {
    border-left: 2px solid #888; /* Thin gray left border */
    border-right: 2px solid #888; /* Thin gray right border */
    transform: scaleY(0);
    transform-origin: center;
}

.pageContent ul li:hover::before,
.pageContent ol li:hover::before {
    transform: scaleX(1); /* Expands the top and bottom borders */
}

.pageContent ul li:hover::after,
.pageContent ol li:hover::after {
    transform: scaleY(1); /* Expands the left and right borders */
}

.pageContent ul li:hover,
.pageContent ol li:hover {
    transform: translateY(-10px); /* Hover effect to make items "pop" */
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4), 0 10px 25px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
    background: linear-gradient(145deg, #2c2f36, #3a3f46); /* Reverse gradient for hover effect */
}

.pageContent ul li h3,
.pageContent ol li h3 {
    color: #f1f1f1; /* Light text for headings */
    font-size: 1.5rem; /* Larger font size */
    margin-bottom: 10px;
}

.pageContent ul li p,
.pageContent ol li p {
    color: #ccc; /* Slightly lighter text for body */
    font-size: 1rem;
    line-height: 1.6;
    margin-top: 10px;
}

.pageContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px; /* Space between items */
}

.pageContent ul li:hover,.pageContent ol li:hover {
opacity:.8;}
.pageContent ul li strong,
.pageContent ol li strong {
    display: block;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
    background: linear-gradient(135deg, #fff, #fff); /* Gradient background */
    -webkit-background-clip: text; /* Clip the background to the text */
    color: transparent; /* Makes the text transparent to show the gradient */
    text-fill-color: transparent; /* Compatibility for text gradient */
    position: relative;
}

/* Styling the first letter */
.pageContent ul li strong::first-letter,
.pageContent ol li strong::first-letter {
    font-size: 1.5em; /* Make the first letter much larger */
    color: #fff; /* Solid color for the first letter */
    font-weight: 900; /* Make the first letter bolder */
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5); /* Add a shadow for depth */
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.pageContent ul li strong span,
.pageContent ol li strong span {
    display: inline-block;
    padding-left: 2.5em; /* Space for the first letter */
}


.carousel-control.left {
cursor:url(../img/cursor-arrow-prev.png) 24 24,auto;}
.carousel-control.right {
cursor:url(../img/cursor-arrow.png) 24 24,auto;}
.dummy {
cursor:url(../img/cursor-plus.png) 24 24,auto;}
.custom-cursor {
width:20px;
    height:20px;
    background-color:rgba(255,0,0,0.6);
    border-radius:50%;
    position:fixed;
    transform:translate(-50%,-50%);
    pointer-events:none;
    mix-blend-mode:difference;
    transition:width 0.2s ease,height 0.2s ease,background-color 0.2s ease;
    z-index:1000;}
.custom-cursor.hidden {
opacity:0;}
.custom-cursor.hovered {
width:40px;
    height:40px;
    background-color:rgba(0,255,0,0.6);}
.accordion-button,.accordion-item {
background:transparent;
    color:#fff;}
.faq .accordion-button {
color:#ffffff;}
.closebtn {
display:none;}
.slick-slide {
display:block;}
input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active {
-webkit-background-clip:text;
    -webkit-text-fill-color:#ffffff;
    transition:background-color 5000s ease-in-out 0s;
    box-shadow:inset 0 0 20px 20px #23232329;}
.contact .php-email-form input:focus,.contact .php-email-form textarea:focus {
color:#fff;}
.contact .php-email-form input,.contact .php-email-form textarea {
border:1px solid #4b4b4b}
.imgfact {
padding-left:60px;}
.slick-dots {
display:flex;
    justify-content:center;
    text-align:center;
    margin:0;
    padding:1rem 0;
    list-style-type:none;
    li {
margin:0 0.25rem;
    display:inline-block;}
button {
display:inline-block;
    width:1rem;
    height:1rem;
    padding:0;
    border:none;
    border-radius:100%;
    background-color:#fff;
    text-indent:-9999px;}
li.slick-active button {
background-color:#be3838;}}
@media (min-width:768px) and (max-width:1024px) {
.fixedabove {
height:600px;}
.hero-img {
display:none;}}
@media (min-width:0px) and (max-width:767px) {
.row {
display:block;}
#features2,#features3,#faq {
display:none;}
.fixedabove {
height:850px;}
.mainsliderbg {
padding:10% 15px;}
#text3d {
font-size:2.7rem;
    line-height:60px;
    margin-top:20%;}
#about {
padding:15px 0px;}
.about .content {
padding:10px;}
.sideleft {
top:45%}
.show-menu {
display:block !important;}
.imgfact {
padding-left:0px;}
.mobnavbar {
width:100%;
    position:fixed;
    top:93px;
    height:100%;
    top:0px;
    right:0px;
    bottom:0px;
    background:#2c2828;}
.navbar {
width:100%;}
.navbar .show-menu {
display:block;
    background:#000;
    color:#fff;
    width:100%;
    position:absolute;
    height:500px;
    top:-20px;
    padding:10px;}
.navbar .show-menu a,.navbar .show-menu a:focus {
color:#fff;}
.navbar .show-menu .dropdown ul {
background:#353535;}
.mobile-nav-toggle {
right:0px;
    position:absolute;}
.closebtn {
position:absolute;
    right:10px;
    top:10px;
    z-index:999;
    display:block;}
.pageContent ul li,.pageContent ol li {
width:100%;
    margin:2%;}
.contact .php-email-form .submitbtn {
font-size:17px;}
.campaignpage #text3d {
margin-top:0%;
    font-size:1.2rem;
    line-height:10px;}
#hero .campaignpage .imgsz {
margin-top:20%;}
.campaignpage .content_sec,.campaignpage .headerdesign {
font-size:7px;}
.campaignpage .claimnow {
font-size:0.9em;
    margin-bottom:15px;}
.campaignpage .claimnow .tcst {
font-size:7px;}
.hero .hero-img {
margin-top:0px;}
.campaignpage form .col-md-4,.campaignpage form .col-md-3 {
margin-top:10px;}
.campaignpage form {
margin-top:10px;}
.campaignpage .claimnow {
padding:10px 10px;
    font-size:0.8em;
    float:left;
    margin-bottom:0px;}
.campaignpage input,.campaignpage input:focus,.campaignpage input:hover {
padding:10px 10px;}
#hero .homedes,#hero .imgsz {
width:70px;}
#hero #text3d {
margin-top:0;}
.fixedabove video {
object-fit:cover;}}
.fixedabove video {
object-fit:cover;}
.developer-cursor {
position:fixed;
    transform:translate(-50%,-50%);
    pointer-events:none;
    z-index:1000;
    font-size:24px;
    color:#61dafb;
    font-family:'Courier New',Courier,monospace;
    font-weight:bold;
    transition:color 0.2s ease,transform 0.2s ease;}
.cursor-symbol {
display:inline-block;
    animation:blink 1s steps(2,start) infinite;}
@keyframes blink {
0% {
opacity:1;}
50% {
opacity:0;}
100% {
opacity:1;}}
.developer-cursor.hidden {
opacity:0;}
.developer-cursor.hovered {
transform:translate(-50%,-50%) scale(1.2);
    color:#ff79c6;}
html {}
#tsparticles {
position:fixed;
    width:100%;
    height:100%;
    top:0;
    left:0;
    pointer-events:none;
    z-index:0;}
#tsparticles canvas {
pointer-events:none !important;}
.developer-cursor {
position:fixed;
    pointer-events:none;
    z-index:9999;
    width:20px;
    height:20px;
    transform:translate(-50%,-50%);
    transition:transform 0.1s ease;}
.hidden {
display:none;}
.hovered .cursor-symbol {
font-weight:bold;
    font-size:1.5em;}
.particle-blast {
position:fixed;
    color:white;
    font-weight:bold;
    opacity:1;
    pointer-events:none;
    transform:translate(0,0);
    transition:transform 0.6s ease-out,opacity 0.6s ease-out;
    text-align:center;
    line-height:1.2;
    font-size:12px;
    display:flex;
    align-items:center;
    justify-content:center;}